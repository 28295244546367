import React from 'react';

import { DefaultLocale } from '@utils/paths/locale';
import { Paths } from '@utils/paths/paths';
import { PathsConfig } from '@utils/paths/paths-config';

import { getStory, getGlobalStories } from '@services/storyblok';
import { useStoryblok } from '@services/storyblok/bridge';
import { Page } from '@theme/Page/Page';

const pageId = Paths.NotFound;

export default function ErrorPage({ story, preview, pageContext, locale }) {
  const finalStory = useStoryblok(story, preview);

  return <Page story={finalStory} locale={locale} pageContext={pageContext} withoutFooter withoutHeader />;
}

export const getStaticProps = async props => {
  const { preview = false } = props;

  const storyblokSlug = PathsConfig[pageId].storyblokSlug;
  const { data } = await getStory({
    slug: storyblokSlug,
    preview: true,
  });
  const globalStories = await getGlobalStories({ preview: true });

  return {
    props: {
      story: data ? data.story : false,
      preview,
      locale: DefaultLocale,
      pageContext: {
        globalStories,
        currentPageId: pageId,
      },
    },
  };
};
